import React,{useEffect, useState} from "react";
import {
    Radio,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    FormControl,
    Card,
    CardContent,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    Input,
    FormHelperText,
    TextField,
  } from "@mui/material";
import { pink } from "@mui/material/colors";



const GetEstimate = ({ OnSendFormData,EnteredFormData }) => {
    const [formData, setFormData] = useState({
        email: EnteredFormData!=null ? EnteredFormData.email:'',
        name: EnteredFormData!=null ? EnteredFormData.name:'',
        mobileNumber: EnteredFormData!=null ? EnteredFormData.mobileNumber:'',
        propertyName: EnteredFormData!=null ? EnteredFormData.propertyName:'',
      });
      const handleChange = (fieldName) => (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));

        validateField(fieldName,value)
      };
      
      const [errors, setErrors] = useState({});
    
      useEffect(()=>{
        if(formData.email!==null && formData.mobileNumber!==null && formData.name!==null && formData.propertyName!==null){
          if(!Object.keys(errors).length ) return; // No errors so far
          
          OnSendFormData(formData)
        }
      
      },[formData.email && formData.name && formData.mobileNumber && formData.propertyName])



const validateField = (fieldName, value) => {
  const newErrors = { ...errors };

  // Email validation
  if (fieldName === "email") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    newErrors.email = !value.trim() || !emailRegex.test(value)
      ? "Invalid email address"
      : "";
  }

  // Name validation
  if (fieldName === "name") {
    newErrors.name = !value.trim() ? "Name is required" : "";
  }

  // Mobile number validation
  if (fieldName === "mobileNumber") {
    const mobileRegex = /^[0-9]{10}$/;
    newErrors.mobileNumber = !value.trim() || !mobileRegex.test(value)
      ? "Invalid mobile number"
      : "";
  }

  // Property name validation
  if (fieldName === "propertyName") {
    newErrors.propertyName = !value.trim() ? "Property Name is required" : "";
  }

  setErrors(newErrors);
};
    
 
      


    return (
      <div className="quote-step">
        <p className="title">Your Wardrobe estimate is almost ready!</p>
        <div style={{marginTop:"50px",display:"flex",flexDirection:"column",width:"100%",alignItems:'center',gap:15}}>
        <FormControl sx={{ marginBottom: 2,display:'flex',alignItems:'center' }} fullWidth>
                <TextField
                    id="outlined-basic-email"
                    className="form-input"
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={formData.email}
                    onChange={handleChange("email")}
                    sx={{width:"500px"}}
                />
                {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
      </FormControl>
      <FormControl sx={{ marginBottom: 2,display:'flex',alignItems:'center' }} fullWidth>
        
        <TextField id="outlined-basic name" className="form-input" label="Name" variant="outlined" type="text" value={formData.name} onChange={handleChange("name")} sx={{width:"500px"}}/>
      
        {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
      </FormControl>

      <FormControl sx={{ marginBottom: 2,display:'flex',alignItems:'center' }} fullWidth>
        <TextField id="outlined-basic mobileNumber" className="form-input" label="Mobile Number" variant="outlined" type="tel" value={formData.mobileNumber} onChange={handleChange("mobileNumber")} sx={{width:"500px"}}/>
        {errors.mobileNumber && (
          <FormHelperText error>{errors.mobileNumber}</FormHelperText>
        )}
      </FormControl>

      <FormControl sx={{ marginBottom: 2,display:'flex',alignItems:'center' }} fullWidth>
        
        <TextField id="outlined-basic propertyName" className="form-input" label="Property Name" variant="outlined" type="text" value={formData.propertyName} onChange={handleChange("propertyName")} sx={{width:"500px"}}/>
      
        {errors.propertyName && (
          <FormHelperText error>{errors.propertyName}</FormHelperText>
        )}
      </FormControl>
      </div>
      </div>
    );
  };


  export default GetEstimate