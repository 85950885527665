import React, { useState } from "react";
import {
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import LaminateImg from "../../../assets/Laminate.jpg";
import VeenerImg from "../../../assets/Veener.jpg";
import PUCPaintImg from "../../../assets/PU Color Paint.jpg";
import WoodWickerImg from "../../../assets/WoodorWicker.jpg";
import LaquareGlossImg from "../../../assets/Laqure Gloss.jpg";
import ArcalicGlass from "../../../assets/Acralic glass.jpg";

const DoorFinish = ({
  onSelectdoorfinishType,
  openModal,
  selecteddoorfinishType,
}) => {
  const data = [
    {
      id: 1,
      doorfinish: "Laminate",
      doorfinishprice: 350,
      img:LaminateImg,
      carcasetype: [
        {
          id: 1,
          type: "White Laminate",
          price: 0,
        },
        
      ],
    },
    {
      id: 2,
      doorfinish: "Veneer",
      doorfinishprice: 750,
      img:VeenerImg,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    {
      id: 3,
      doorfinish: "PUC Paint",
      doorfinishprice: 700,
      img:PUCPaintImg,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    {
      id: 4,
      doorfinish: "Wood / Wicker",
      doorfinishprice: 850,
      img:WoodWickerImg,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    {
      id: 5,
      doorfinish: "Laquare Gloss",
      doorfinishprice: 900,
      img:LaquareGlossImg,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    {
      id: 6,
      doorfinish: "Acrylic Fiberglass Material 1mm",
      doorfinishprice: 600,
      img:ArcalicGlass,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    {
      id: 7,
      doorfinish: "Acrylic Fiberglass Material 2mm",
      doorfinishprice: 1350,
      img:ArcalicGlass,
      carcasetype: [
        {
          id: 1,
          type: "Fabric/ Wooden Finsih Laminate ",
          price: 60,
        },
        
      ],
    },
    
 

    // Add more objects as needed
  ];
  const [value, setValue] = useState(
    selecteddoorfinishType ? selecteddoorfinishType.doorfinish : ""
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    const selectedfinish = data.find(
      (item) => item.doorfinish === event.target.value
    );

    onSelectdoorfinishType(selectedfinish);
  };

  return (
    <div className="quote-step">
      <p className="title">Select Your Door Finish Type</p>
      <p>
        To know more about this,
        <span onClick={() => openModal()} className="click">
          Click here
        </span>
      </p>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{display: "flex",
          gap:"10px",
          flexDirection:"row",
          justifyContent:'center',
            }}
        >
          {data.map((item, index) => (
            <Card
              key={index}
              sx={{
                mb: "20px",
                  minWidth: "300px",
                  maxWidth:"300px",
                padding: "10px",
                display:'flex',
                flexDirection:'column' ,
                border:
                  value === item.carcasetype
                    ? "2px solid #704c52"
                    : "2px solid transparent",
                cursor: "pointer",
                "&:hover": {
                  borderColor: "#704c52",
                },
              }}
              // onClick={() => handleChange({ target: { value: item.name } })}
            >
              <img src={item.img} style={{width:"290px"}}/>
              <FormControlLabel
                sx={{ width: "300px" }}
                control={
                  <Radio
                    sx={{
                      color: "#704c52",
                      "&.Mui-checked": {
                        color: "#704c52",
                      },
                    }}
                  />
                }
                value={item.doorfinish}
                label={item.doorfinish}
              />
             
                    <Typography variant='body2'>{item.carcasetype.map((obj)=>obj.type)}</Typography>
               
            </Card>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default DoorFinish;
