import React, { useState } from "react";
import {
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import { pink } from "@mui/material/colors";

import SwingDoorImg from "../../../assets/swingdoorShoeRack.jpg";
import SoftDoorImg from "../../../assets/softcloseshoerack.jpg";
import SlideDoorImg from "../../../assets/slideshoerack.jpg";


const ShoeRackType = ({
  onSelectShoeRackType,
  openModal,
  selectedShoeRackType,
}) => {
  const data = [
    { name: "Swing Door", img: SwingDoorImg,price:625 },
    { name: "Slide Door", img: SlideDoorImg,price:625 },
    { name: "Normal Soft Close", img: SoftDoorImg,price:625 },
    // { name: "Kitchen Top and Bottom", img: KitchenBoth },
  ];
  const [value, setValue] = useState(
    selectedShoeRackType ? selectedShoeRackType.name : ""
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    const selectedShoeRack = data.find(
      (item) => item.name === event.target.value
    );

    onSelectShoeRackType(selectedShoeRack);
  };

  return (
    <div className="quote-step">
      <p className="title">Select Your Shoe Rack Type</p>
      <p>
        To know more about this,
        <span onClick={() => openModal()} className="click">
          Click here
        </span>
      </p>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{display: "flex",
          gap:"10px",
          flexDirection:"row",
          // alignItems:'center',
          justifyContent:'center',
            }}
            className="radio-group"
        >
          {data.map((item, index) => (
            <Card
              key={index}
              className="radioCard"
              sx={{
                mb: "20px",
                  minWidth: "300px",
                  maxWidth:"300px",
                padding: "10px",
                display:'flex',
                flexDirection:'column' ,
                border:
                  value === item.name
                    ? "2px solid #704c52"
                    : "2px solid transparent",
                cursor: "pointer",
                "&:hover": {
                  borderColor: "#704c52",
                },
              }}
              // onClick={() => handleChange({ target: { value: item.name } })}
            >
              <img src={item.img} style={{width:"290px"}}/>
              <FormControlLabel
                sx={{ width: "300px" }}
                control={
                  <Radio
                    sx={{
                      color: "#704c52",
                      "&.Mui-checked": {
                        color: "#704c52",
                      },
                    }}
                  />
                }
                value={item.name}
                label={item.name}
              />
            </Card>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ShoeRackType;
