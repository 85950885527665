import React, { useState } from "react";
import {
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import { pink } from "@mui/material/colors";

import SwingDoorImg from "../../../assets/swingdoorShoeRack.jpg";
import SoftDoorImg from "../../../assets/softcloseshoerack.jpg";
import SlideDoorImg from "../../../assets/slideshoerack.jpg";


const WardrobeType = ({
  onSelectWardrobeType,
  openModal,
  selectedwardrobeType,
  OnSelectWardrobeCategory,
  selectedwardrobeCategory,
}) => {
  const data = [
    { name: "Swing Door",price:625 },
    { name: "Slide Door",category:[{
      id:1,name:"Ftc",price:36000,},{id:2,name:"Normal Soft Close",price:8000}
    ]},
    // { name: "Kitchen Top and Bottom", img: KitchenBoth },
  ];
  const [value, setValue] = useState(
    selectedwardrobeType ? selectedwardrobeType.name : ""
  );

  const [subValue, setSubValue] = useState(selectedwardrobeCategory ? selectedwardrobeCategory.name : ""); // Define subValue state



  const handleChange = (event) => {
    const selectedWardrobe = event.target.value;
    const selectedItem = data.find((item) => item.name === selectedWardrobe);
  
    // Use data.find and check if obj.category exists
    const selectedSubItem = selectedItem?.category?.find(
      (subItem) => subItem.name === subValue
    );
  
    setValue(selectedWardrobe);
    onSelectWardrobeType(selectedItem, selectedSubItem);
  };
  
  const handleSubChange = (event) => {
    const selectedSubCategory = event.target.value;
    const selectedItem = selectedwardrobeType.category?.find((item)=>item.name === selectedSubCategory)
    setSubValue(selectedSubCategory);
    OnSelectWardrobeCategory(selectedItem);
  };
  
  
  

  return (
    <div className="quote-step">
      <p className="title">Select Your Wardrobe Type</p>
      <p>
        To know more about this,
        <span onClick={() => openModal()} className="click">
          Click here
        </span>
      </p>
      <FormControl>
  <RadioGroup
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={value}
    onChange={handleChange}
    sx={{
      display: "flex",
      gap: "10px",
      flexDirection: "column",
      justifyContent: "center",
    }}
    className="radio-group"
  >
    {data.map((item, index) => (
      <Card
        key={index}
        className="radioCard"
        sx={{
          mb: "20px",
          minWidth: "300px",
          maxWidth:"300px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          border:
            value === item.name ? "2px solid #704c52" : "2px solid transparent",
          cursor: "pointer",
          "&:hover": {
            borderColor: "#704c52",
          },
        }}
      >
        
        <FormControlLabel
          sx={{ width: "300px" }}
          control={
            <Radio
              sx={{
                color: "#704c52",
                "&.Mui-checked": {
                  color: "#704c52",
                },
              }}
            />
          }
          value={item.name}
          label={item.name}
        />
        {value === item.name && item.category && (
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={`controlled-radio-buttons-group-${item.name}`}
            value={subValue}
            onChange={handleSubChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "10px",
              gap:"10px"
            }}
            className="radio-group"
          >
            {item.category.map((subItem, subIndex) => (
              <Card
                key={subIndex}
                className="radiocard-sub"
                sx={{
                  mb: "20px",
                  minWidth: "150px",
                  maxWidth: "150px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  border:
                    subValue === subItem.name
                      ? "2px solid #704c52"
                      : "2px solid transparent",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#704c52",
                  },
                }}
              >
                <FormControlLabel
                  sx={{ width: "150px" }}
                  control={
                    <Radio
                      sx={{
                        color: "#704c52",
                        "&.Mui-checked": {
                          color: "#704c52",
                        },
                      }}
                    />
                  }
                  value={subItem.name}
                  label={subItem.name}
                />
              </Card>
            ))}
          </RadioGroup>
        )}
      </Card>
    ))}
  </RadioGroup>
</FormControl>

    </div>
  );
};

export default WardrobeType;
