import React,{useEffect, useState} from "react";
import {
    Radio,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    FormControl,
    Card,
    CardContent,
    Typography,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";




const FrameType = ({ onSelectFrameType, openModal,selectedFrameType,onSelectUserMeasureMents,onSelectAdminMeasurements,selectedUserM,selectedAdminM,selectedKitchenType }) => {
  const [usermeasurementData,setUserMeasurementData]=useState({
    width:selectedUserM!==null ? selectedUserM.width:"",
    depth:selectedUserM!==null ? selectedUserM.depth:"",
    height:selectedUserM!==null ? selectedUserM.height:"",
  });
  const[adminsideMeasurementData,setAdminSideMeasurementData]=useState({
    width:selectedAdminM!==null?selectedAdminM.width:"",
    depth:selectedAdminM!==null?selectedAdminM.depth:"",
    height:selectedAdminM!==null?selectedAdminM.height:"",

  })


    const [age, setAge] = React.useState('');


    const data = [
      { name: "BWP Ply wood", Price: 1 },
      { name: "Aluminum Frame ", Price: 0.8 },
    ];
    const [value, setValue] = useState(selectedFrameType? selectedFrameType.name : "");

    const handleChange = (event) => {
      setValue(event.target.value);
      const selectedKitchen = data.find(
        (item) => item.name === event.target.value
      );
     
      onSelectFrameType(selectedKitchen)
    };

      useEffect(()=>{
        onSelectUserMeasureMents(usermeasurementData)
        onSelectAdminMeasurements(adminsideMeasurementData)


      },[usermeasurementData,adminsideMeasurementData])
      
    
    // Generate width options from 60 to 234
    const widthOptions = Array.from({ length: 175 }, (_, index) => 60 + index);
    
  // Generate depth options from 12 to 23
  const depthBottomOptions = Array.from({ length: 12 }, (_, index) => 12 + index);
  const depthTopOptions = Array.from({ length: 7 }, (_, index) => 6 + index);

  const [depthData, setDepthData] = useState(depthTopOptions);

  useEffect(() => {
    if (selectedKitchenType && selectedKitchenType.name === "Kitchen Bottom") {
      setDepthData(depthBottomOptions);
    } else {
      setDepthData(depthTopOptions);
    }
  }, [selectedKitchenType]);

    // Generate height options from 30 to 36
    const heightOptions = Array.from({ length: 7 }, (_, index) => 30 + index);

    const handleMeasurements =(fieldName)=>(event)=>{
      const {value}=event.target;
      if(fieldName==="width" && value){
        const numericValue = parseFloat(value)
        if (numericValue <= 60) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5,
          }));
        } 
        if (numericValue >= 61 && numericValue <= 66) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5.5,
          }));
        } 
        if (numericValue >= 67 && numericValue <= 72) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6,
          }));
        } 
        if (numericValue >= 73 && numericValue <= 78) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6.5,
          }));
        } 
        if (numericValue >= 79 && numericValue <= 84) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7,
          }));
        } 
        if (numericValue >= 85 && numericValue <= 90) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7.5,
          }));
        } 
        if (numericValue >= 91 && numericValue <= 96) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 8,
          }));
        } 
        if (numericValue >= 97 && numericValue <= 102) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 8.5,
          }));
        } 
        if (numericValue >= 103 && numericValue <= 108) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 9,
          }));
        } 
        if (numericValue >= 109 && numericValue <= 114) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 9.5,
          }));
        } 
        if (numericValue >= 115 && numericValue <= 120) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 10,
          }));
        } 
        if (numericValue >= 121 && numericValue <= 126) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 10.5,
          }));
        } 
        if (numericValue >= 127 && numericValue <= 132) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 11,
          }));
        } 
        if (numericValue >= 133 && numericValue <= 138) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 11.5,
          }));
        } 
        if (numericValue >= 138 && numericValue <= 144) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 12,
          }));
        } 
        if (numericValue >= 145 && numericValue <= 150) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 12.5,
          }));
        } 
        if (numericValue >= 151 && numericValue <= 156) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 13,
          }));
        } 
        if (numericValue >= 157 && numericValue <= 162) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 13.5,
          }));
        } 
        if (numericValue >= 163 && numericValue <= 168) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 14,
          }));
        } 
        if (numericValue >= 169 && numericValue <= 174) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 14.5,
          }));
        } 
        if (numericValue >= 175 && numericValue <= 180) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 15,
          }));
        } 
        if (numericValue >= 181 && numericValue <= 186) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 15.5,
          }));
        } 
        if (numericValue >= 187 && numericValue <= 192) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 16,
          }));
        } 
        if (numericValue >= 193 && numericValue <= 198) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 16.5,
          }));
        } 
        if (numericValue >= 199 && numericValue <= 204) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 17,
          }));
        } 
        if (numericValue >= 205 && numericValue <= 210) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 17.5,
          }));
        } 
        if (numericValue >= 211 && numericValue <= 216) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 18,
          }));
        } 
        if (numericValue >= 217 && numericValue <= 222) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 18.5,
          }));
        } 
        if (numericValue >= 223 && numericValue <= 228) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 19,
          }));
        } 
        if (numericValue >= 229 && numericValue <= 234) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 19.5,
          }));
        } 
        
      }
      if(fieldName==="depth"&&value){
        const numericValue = parseFloat(value)
        if (numericValue >= 12 && numericValue <= 23 || numericValue >=6 && numericValue<=12) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 1.00,
          }));
        } 
      }
      if(fieldName==="height"&&value){
        const numericValue = parseFloat(value)
        if (numericValue >= 30 && numericValue <= 36) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 3,
          }));
        } 
      }
      
        setUserMeasurementData((prevdata)=>({
          ...prevdata,[fieldName]: value
        }))

      
      
      

     

    }

   
    return (
      <div className="quote-step">
        <p className="title">Select Your Frame Type</p>
        <p>
          To know more about this,
          <span onClick={() => openModal()} className="click">
            Click here
          </span>
        </p>
        <FormControl >
        <Typography sx={{mb:"10px",fontWeight:"bold",textAlign:"center"}}>Select Your Frame Type</Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            className="radio-container"
            
            sx={
                {
                    display:"flex",
                    flexDirection:"row",
                    gap:10,
                    alignItems:'center',
                    justifyContent:'center'
                }
                
            }
          >
            {data.map((item, index) => (
              <Card
                key={index}
                sx={{
                  mb: "20px",
                  minWidth: "100px",
                  maxWidth:"200px",
                  padding: "10px",
                  border:
                    value === item.name
                      ? "2px solid #704c52"
                      : "2px solid transparent",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#704c52",
                  },
                }}
                // onClick={() => handleChange({ target: { value: item.name } })}
              >
                <FormControlLabel
                  sx={{ width: "300px" }}
                  control={<Radio  sx={{
          color: "#704c52",
          '&.Mui-checked': {
            color: "#704c52",
          },
        }}/>}
                  value={item.name}
                  label={item.name}
                />
              </Card>
            ))}
          </RadioGroup>
       
        </FormControl>
        <Typography sx={{mb:"10px",fontWeight:"bold"}}>Select Your Kitchen Dimensions</Typography>
        <div style={{display:'flex',gap:"20px",marginTop:"70px"}} className="drop-down-container">
       

        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Width</InputLabel>
        <Select

          className="drop-down"
          value={usermeasurementData.width}
          label="Width"
          type="width"
          onChange={handleMeasurements("width")}
          sx={{width:"150px"}}
        >
        {
          widthOptions.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Depth</InputLabel>
        <Select
           className="drop-down"
       
          value={usermeasurementData.depth}
          label="Depth"
          type="depth"
          onChange={handleMeasurements("depth")}
          sx={{width:"150px"}}
        >
        {
          
          depthData.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Height</InputLabel>
        <Select
        className="drop-down"
          value={usermeasurementData.height}
          label="Height"
          type="height"
          onChange={handleMeasurements("height")}
          sx={{width:"150px"}}
        >
        {
          heightOptions.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        
        

        </div>
        
      </div>
    );
  };


  export default FrameType