import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import "./style.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import KitchenType from "./Components/Step1";
import MaterialType from "./Components/Step2";
import FrameType from "./Components/Step3";
import GetEstimate from "./Components/Step4";
import { PartyMode } from "@mui/icons-material";
import ShoeRackType from "./Components/Step1";
import CarcaseMaterial from "./Components/Step3";
import DoorFinish from "./Components/Step2";
import WardrobeType from "./Components/Step1";
import WardrobeFinish from "./Components/Step2";
import emailjs from '@emailjs/browser';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { TailSpin } from "react-loader-spinner";

const WardrobeQuote = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [loading,setloading]=useState(false);
  const totalStep = 4;
  const [openModal, setOpenModal] = useState(false);
  // const [selectedKitchenType, setSelectedkitchenType] = useState(null);
  const [formData,setFormData]=useState({
    selectedWardrobeType:null,
    selectedWardrobeCategory:null,
    selectedWardrobeFinishType:null,
    selectedCarcaseType:null,
    usermeasurements:null,
    adminmeasurements:null,
    mailform:null,
  })

  const [totalCost,setTotalCost]=useState(0)
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleWardrobeTypeChange = (wardrobe,category)=>{
   
    setFormData((prevData)=>({
    ...prevData,
    selectedWardrobeType:wardrobe,
    selectedWardrobeCategory:category,
    }))
  }
  const handleWardrobeCategoryChange = (category)=>{
    setFormData((prevData)=>({
    ...prevData,
    selectedWardrobeCategory:category,
    }))
  }

  const handleMaterialLayoutChange = (MaterialType) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCarcaseType:MaterialType,
    }));
  };
  const handleWardrobeFinishChange = (doorfinish) => {
    setFormData((prevData) => ({
      ...prevData,
     selectedWardrobeFinishType:doorfinish,
    }));
  };
  const handleFormMail= (form) => {
    setFormData((prevData) => ({
      ...prevData,
      mailform: form,
    }));
  };
  const handleUserMeasurements = (userM)=>{
    setFormData((prevData)=>({
      ...prevData,
     usermeasurements:userM
    }))
  }
  const handleAdminMeasurements = (adminM)=>{
    setFormData((prevData)=>({
      ...prevData,
     adminmeasurements:adminM
    }))
  }

  const serviceId = "service_plyl1aj";
  const templateId = "template_ducg6dj";
  const PublicKey = "OV4RVGFMZ96bdqkJ0";

  const handlesubmit=(success,error)=>{
    const {selectedWardrobeType,selectedWardrobeFinishType,selectedWardrobeCategory,selectedCarcaseType,adminmeasurements,mailform,usermeasurements}=formData
    const selectedWardrobe = "Wardrobe";
    const selectedLayout = selectedWardrobeType?.name;
    const LayoutPrice = selectedWardrobeType?.price;
    const SelectedLayoutCategory = selectedWardrobeCategory?.name || '';
    const LayoutCategoryPrice = selectedWardrobeCategory?.price || '';
    const SelectedMaterial = selectedWardrobeFinishType.doorfinish;
    const MaterialPrice = selectedWardrobeFinishType.doorfinishprice;
    const SelectedFrame = selectedCarcaseType.name;
    const SelectedFramePrice = selectedCarcaseType.Price;
    const CarcaseType = selectedWardrobeFinishType.carcasetype[0].type;
    const CarCasePrice = selectedWardrobeFinishType.carcasetype[0].price;
    const AdminWidth = adminmeasurements.width;
    const Adminheight = adminmeasurements.height;
    const AdminDepth = adminmeasurements.depth;
    const UserWidth = usermeasurements.width;
    const UserHeight = usermeasurements.height;
    const UserDepth = usermeasurements.depth;
    const username = mailform.name;
    const useremail = mailform.email;
    const usercontact = mailform.mobileNumber;
    const userproperty = mailform.propertyName;

 const template_params = {
  user_name:username,
  user_mobile:usercontact,
  user_email:useremail,
  user_property:userproperty,
  selected_interior:selectedWardrobe,
  selected_layout:selectedLayout || SelectedLayoutCategory ,
  selected_layout_price:LayoutPrice || LayoutCategoryPrice,
  selected_material:SelectedMaterial,
  selected_material_price:MaterialPrice,
  selected_carcase:CarcaseType,
  selected_carcase_price:CarCasePrice,
  selected_frame:SelectedFrame,
  selected_frame_price:SelectedFramePrice,
  selected_width:UserWidth,
  selected_depth:UserDepth,
  selected_height:UserHeight,
  admin_width:AdminWidth,
  admin_depth:AdminDepth,
  admin_height:Adminheight,
  total_cost:Math.round(totalCost),


 }
 setloading(true)
 emailjs.send(serviceId, templateId,template_params, PublicKey,)
      .then((result) => { 
        setloading(false)
          enqueueSnackbar("Your Request Sumbitted Successfully..",{variant:'success'})
          setTimeout(() => {
            window.location.reload()
          }, 3000);
         
      }, (error) => {
        
          enqueueSnackbar("Please fill the requiredfields..",{variant:'error'})
      });


  }
 


  
  const getStepContent = (stepIndex) => {
    // Define your form components for each step
    switch (stepIndex) {
      case 0:
        return <WardrobeType onSelectWardrobeType={handleWardrobeTypeChange} openModal={handleOpenModal} selectedwardrobeType={formData.selectedWardrobeType} OnSelectWardrobeCategory={handleWardrobeCategoryChange} selectedwardrobeCategory={formData.selectedWardrobeCategory}/>;
      case 1:
        return <WardrobeFinish onSelectWardrobefinish={handleWardrobeFinishChange} openModal={handleOpenModal} selectedWardrobefinish={formData.selectedWardrobeFinishType}/>;
      case 2:
        return <CarcaseMaterial onSelectMaterialType={handleMaterialLayoutChange}  openModal={handleOpenModal} selectedMaterialType={formData.selectedCarcaseType} onSelectUserMeasureMents={handleUserMeasurements} selectedUserM={formData.usermeasurements} onSelectAdminMeasurements={handleAdminMeasurements} selectedAdminM={formData.adminmeasurements} />
      case 3:
        return <GetEstimate OnSendFormData={handleFormMail} EnteredFormData={formData.mailform}/>
      // Add more cases as needed
      default:
        return "Unknown stepIndex";
    }
  };


  // Calculation Part Begin
  useEffect(() => {
    if (
      formData.selectedWardrobeType !== null &&
      formData.selectedCarcaseType !== null &&
      formData.selectedWardrobeFinishType !== null &&
      formData.usermeasurements !== null &&
      formData.adminmeasurements !== null
    ) {
      const {
        selectedWardrobeType,
        selectedWardrobeCategory,
        selectedCarcaseType,
        selectedWardrobeFinishType,
        adminmeasurements,
      } = formData;

      const subtotal =
        selectedWardrobeType?.price || selectedWardrobeCategory?.price +
        selectedWardrobeFinishType.doorfinishprice +
        selectedWardrobeFinishType.carcasetype[0].price;

      // Calculate total cost
      const calculatedTotalCost =
        subtotal *
        selectedCarcaseType.Price *
        adminmeasurements.width *
        adminmeasurements.depth *
        adminmeasurements.height;
      setTotalCost(calculatedTotalCost);
      
    } else {
      // Reset totalCost if the condition is not met
      setTotalCost(null);
    }
  }, [formData]); 
  




// Calculation Part Ends 
  
  return (
    <div>
    {loading && <div className="overlay" style={{display:"grid",placeItems:'center'}}> <TailSpin visible={true} height="80" width="80" color="#0f57ff" radius="1"/></div>}
      <header className="flex">
        <div className="left">
          <div className="logo">
          <img src="https://venusinterior.in/wp-content/uploads/2023/09/logo-4.png" alt="LOGO" />
          </div>
        </div>
        <div className="center">
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step key="Step1">
              <StepLabel>Wardrobe Type</StepLabel>
            </Step>
            <Step key="Step2">
              <StepLabel>Material</StepLabel>
            </Step>
            <Step key="Step3">
              <StepLabel>Frame Type</StepLabel>
            </Step>
            <Step key="Step4">
              <StepLabel>Get Quote</StepLabel>
            </Step>
            {/* Add more steps as needed */}
          </Stepper>
        </div>
        <div className="right">
          <p>
            {activeStep+1}/{totalStep}
          </p>
        </div>
      </header>
      <div className="center-body">
        <div className="center-card">
        {getStepContent(activeStep)}
        </div>
        <div className="stepper-btn-container">
          <Button disabled={activeStep === 0} onClick={handleBack}>
            {/* {JSON.stringify(formData, null, 2)} */}
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={activeStep==3?handlesubmit:handleNext} disabled={ activeStep === 0 && formData.selectedWardrobeType === null || activeStep === 1 && formData.selectedWardrobeFinishType ===null || (activeStep ===2 && formData.selectedCarcaseType ===null && formData.usermeasurements ===null) || activeStep ===3 && formData.mailform ===null} sx={{
            backgroundColor:(activeStep === 0 && formData.selectedWardrobeType === null) || (activeStep === 1 && formData.selectedWardrobeFinishType ===null) || (activeStep ===2 && formData.selectedCarcaseType ===null && formData.usermeasurements ===null) ||(activeStep ===3 && formData.mailform ===null) ? "white":"#704c52", '&:hover': { backgroundColor: '#513136' },
          }}>
            {activeStep >= 3 ? "Get My Estimate" : "Next"}
          </Button>
          
        </div>
        
        <Modal open={openModal} onClose={handleCloseModal}>
          <div className="modal-box">
            <div className="modal-header">
              <h5 className="modal-title">Why it matters ?</h5>
              <CloseIcon
                className="close-icon"
                onClick={() => handleCloseModal()}
              />
            </div>
            <div className="modal-body">
              <p className="modal-description">
                The configuration and size of your home gives us a better idea
                of the magnitude of the project and helps us calculate the full
                home interior cost.
              </p>
            </div>
          </div>
        </Modal>
     
      </div>
      {/* <Container>
      <div>
        <Typography>{getStepContent(activeStep)}</Typography>
        <div>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === 4 ? 'Get My Estimate' : 'Next'}
          </Button>
        </div>
      </div>
    </Container> */}
    </div>
  );
};

export default WardrobeQuote;
